import { orderEntity } from "API/modules/Orders";
import assets from "assets";
import { useAlertControls } from "modules/Alerts";
import { AlertTypes } from "modules/Alerts/constants";
import { normalize } from "normalizr";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { store } from "store";
import { AppActions } from "store/App";
import { useAppDispatch } from "store/hooks";
import { NotificationActions } from "store/Notifications";
import {
  NotificationPriority,
  NotificationTypes,
} from "store/Notifications/enums";
import { AppNotification } from "store/Notifications/types";
import { OrderActions } from "store/Orders";
import { OrderKinds } from "store/Orders/enums";
import { Order, OrderItem } from "store/Orders/types";
import { SettingSelectors } from "store/Settings";
import type { SelectedSoundAndAlertOption } from "store/Settings/types";
import { UserActions } from "store/User";
import { Account } from "store/User/types";
import { useNavigateToOrderDetails } from "util/hooks";

export function useEventHandlers() {
  const dispatch = useAppDispatch();

  const navigateToOrderDetails = useNavigateToOrderDetails();

  const { t } = useTranslation();

  const { add: addAlert } = useAlertControls();

  const onOrderChanged = useCallback(
    (data: Order) => {
      const { uuid: id, ...changes } = data;

      dispatch(
        OrderActions.updateOrder({
          id,
          changes,
        })
      );
    },
    [dispatch]
  );

  const onItemsAdded = useCallback(
    (data: { order_uuid: Order["uuid"]; items: Partial<OrderItem>[] }) => {
      const { order_uuid: orderUUID, items } = data;

      dispatch(OrderActions.addItems({ orderUUID, items }));
    },
    [dispatch]
  );

  const onItemsRemoved = useCallback(
    (data: { order_uuid: Order["uuid"]; item_uuids: OrderItem["uuid"][] }) => {
      const { order_uuid: orderUUID, item_uuids: itemUUIDs } = data;

      dispatch(OrderActions.removeItems({ orderUUID, itemUUIDs }));
    },
    [dispatch]
  );

  const onItemsChanged = useCallback(
    (data: { items: Partial<OrderItem>[] }) => {
      const { items } = data;

      const changes = items.map(({ uuid, ...rest }) => ({
        id: uuid,
        changes: rest,
      }));

      dispatch(OrderActions.updateItems(changes));
    },
    [dispatch]
  );

  const onAccountChanged = useCallback(
    (data: Partial<Account>) => {
      const { uuid, ...changes } = data;

      dispatch(UserActions.updateAccount({ uuid, changes }));
    },
    [dispatch]
  );

  const onNotificationCreated = useCallback(
    (data: { notification_data: AppNotification }) => {
      const { notification_data } = data;

      const state = store.getState();
      const selectedSoundAndAlertConfig =
        SettingSelectors.selectSelectedSoundAndAlertConfig(state);

      const selectedSoundOption: SelectedSoundAndAlertOption | undefined =
        (() => {
          switch (notification_data.notification_type) {
            case NotificationTypes.ORDER_CANCELLED:
            case NotificationTypes.ITEM_REMOVED:
              return selectedSoundAndAlertConfig?.order_notifications
                ?.order_cancelled_item_removed;
            case NotificationTypes.ORDER_CHANGED:
            case NotificationTypes.ITEM_CHANGED:
            case NotificationTypes.ITEM_ADDED:
              return selectedSoundAndAlertConfig?.order_notifications
                ?.order_changed_item_changed_item_added;
            case NotificationTypes.COMPANY: {
              switch (notification_data.priority) {
                case NotificationPriority.CRITICAL:
                  return selectedSoundAndAlertConfig?.company_notifications
                    ?.critical;
                case NotificationPriority.HIGH:
                  return selectedSoundAndAlertConfig?.company_notifications
                    .high;
                case NotificationPriority.NORMAL:
                  return selectedSoundAndAlertConfig?.company_notifications
                    ?.medium;
                case NotificationPriority.LOW:
                  return selectedSoundAndAlertConfig?.company_notifications
                    ?.low;
                default:
                  return undefined;
              }
            }
            default:
              return undefined;
          }
        })();

      const source = selectedSoundOption?.sound_option
        ? assets.sounds?.[selectedSoundOption.sound_option]
        : undefined;

      const dismissTimerInMs =
        selectedSoundOption?.alert_display_time_ms_option;

      const soundLoopCount = selectedSoundOption?.sound_playback_config_option
        ? selectedSoundOption.sound_playback_config_option.mode === "repeat"
          ? selectedSoundOption.sound_playback_config_option.times_to_repeat
          : dismissTimerInMs === undefined || dismissTimerInMs === null
          ? undefined
          : 9999
        : undefined;

      addAlert({
        type: AlertTypes.NOTIFICATION,
        meta: { notification: notification_data },
        options: {
          ...notification_data.alert_data,
          sound: source,
          auto_dismiss_timeout_ms:
            dismissTimerInMs > 0 ? dismissTimerInMs : undefined,
          hiddenAlert:
            dismissTimerInMs === undefined || dismissTimerInMs === null,
          soundLoopCount,
        },
      });

      dispatch(NotificationActions.addNotifications([notification_data]));
    },
    [addAlert, dispatch]
  );

  const onNotificationChanged = useCallback(
    (
      data: (Partial<AppNotification> & {
        notification_uuid: AppNotification["uuid"];
      })[]
    ) => {
      const updates = data.map((updateObject) => {
        const { notification_uuid, read_at, accepted_at, ...changes } =
          updateObject;
        return {
          id: notification_uuid,
          changes: {
            ...changes,
            ...(read_at ? { read_at } : {}),
            ...(accepted_at ? { accepted_at } : {}),
          },
        };
      });
      dispatch(NotificationActions.updateNotifications(updates));
    },
    [dispatch]
  );

  const onOrderCreated = useCallback(
    (data: { order_data: string }) => {
      try {
        const orderData = JSON.parse(data.order_data);
        const normalizedData = normalize(orderData, orderEntity);
        const { orders, orderItems } = normalizedData.entities as unknown as {
          orders: Record<string, Order>;
          orderItems: Record<string, OrderItem>;
        };

        const selectedOrder = Object.values(orders)[0];
        const orderKind = selectedOrder?.kind;

        const state = store.getState();
        const selectedSoundAndAlertConfig =
          SettingSelectors.selectSelectedSoundAndAlertConfig(state);

        const selectedSoundOption: SelectedSoundAndAlertOption | undefined =
          (() => {
            switch (orderKind) {
              case OrderKinds.DELIVERY:
                return selectedSoundAndAlertConfig?.new_order?.delivery;
              case OrderKinds.CUSTOMER_PICKUP:
                return selectedSoundAndAlertConfig?.new_order?.collection;
              case OrderKinds.DRIVE_THRU:
                return selectedSoundAndAlertConfig?.new_order?.drive_thru;
              case OrderKinds.THIRD_PARTY_DELIVERY:
                return selectedSoundAndAlertConfig?.new_order
                  ?.third_party_delivery;
              case OrderKinds.WALK_IN:
                return selectedSoundAndAlertConfig?.new_order?.walk_in;
              default:
                return undefined;
            }
          })();

        const source = selectedSoundOption?.sound_option
          ? assets.sounds?.[selectedSoundOption.sound_option]
          : undefined;

        const dismissTimerInMs =
          selectedSoundOption?.alert_display_time_ms_option;

        const soundLoopCount = selectedSoundOption?.sound_playback_config_option
          ? selectedSoundOption?.sound_playback_config_option?.mode === "repeat"
            ? selectedSoundOption?.sound_playback_config_option?.times_to_repeat
            : dismissTimerInMs === undefined || dismissTimerInMs === null
            ? undefined
            : 9999
          : undefined;

        addAlert({
          type: AlertTypes.BASE,
          options: {
            dismissible: true,
            auto_dismiss_timeout_ms:
              dismissTimerInMs > 0 ? dismissTimerInMs : undefined,
            sound: source,
            hiddenAlert:
              dismissTimerInMs === undefined || dismissTimerInMs === null,
            soundLoopCount,
          },
          meta: {
            title: `#${selectedOrder?.description}`,
            createdAt: new Date().toISOString(),
            message: t("components.newOrderHasBeenCreated", {
              orderKind: orderKind,
            }),
            priority: NotificationPriority.NORMAL,
            onPress: () => {
              if (selectedOrder?.uuid) {
                navigateToOrderDetails(selectedOrder.uuid);
              }
            },
          },
        });

        dispatch(OrderActions.addOrders({ orders, orderItems }));
      } catch (e) {
        console.error("pusher:onOrderCreated ", e);
      }
    },
    [addAlert, dispatch, navigateToOrderDetails, t]
  );

  const handleEvent = useCallback(
    (event: string, data: any) => {
      console.log(event, data);
      dispatch(AppActions.setUpdatedAt());

      switch (event) {
        case "order_created":
          onOrderCreated(data);
          break;
        case "order_changed":
          onOrderChanged(data);
          break;
        case "items_added":
          onItemsAdded(data);
          break;
        case "items_removed":
          onItemsRemoved(data);
          break;
        case "items_changed":
          onItemsChanged(data);
          break;
        case "account_changed":
          onAccountChanged(data);
          break;
        case "notification_created":
          onNotificationCreated(data);
          break;
        case "notification_changed":
          onNotificationChanged(data);
          break;
      }
    },
    [
      dispatch,
      onAccountChanged,
      onItemsAdded,
      onItemsChanged,
      onItemsRemoved,
      onNotificationChanged,
      onNotificationCreated,
      onOrderChanged,
      onOrderCreated,
    ]
  );

  return handleEvent;
}
