import { MaterialCommunityIcons } from "@expo/vector-icons";
import assets from "assets";
import { Text, withErrorBoundary } from "components";
import { Audio } from "expo-av";
import { PriorityIcon } from "navigation/components/SideBar/NotificationButton/PriorityIcon";
import { RouteNames } from "navigation/linkingConfig";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useAppSelector } from "store";
import { NotificationPriority } from "store/Notifications/enums";
import { OrderKinds } from "store/Orders/enums";
import { SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";
import { getIconNameForOrderKind } from "util/helpers";
import { SectionContainer } from "../components/Section";
import Setting from "../components/Setting";
import { SettingError } from "../components/SettingError";
import { AlertOptionModal } from "./AlertOptionModal";
import { AlertRow } from "./AlertRow";
import type { ModalVisibilityObject } from "./types";

export const Alerts = withErrorBoundary(
  () => {
    const [sound, setSound] = useState<Audio.Sound | undefined>(undefined);

    async function playSound(name: keyof typeof assets.sounds) {
      const soundAsset = assets?.sounds?.[name];
      if (soundAsset) {
        try {
          const { sound } = await Audio.Sound.createAsync(
            assets?.sounds?.[name]
          );
          setSound(sound);

          await sound.playAsync();
        } catch (error) {
          console.error("Failed to play sound:", error);
        }
      }
    }

    useEffect(() => {
      return sound
        ? () => {
            sound.unloadAsync().catch();
          }
        : undefined;
    }, [sound]);

    const { t } = useTranslation();

    const [selectedModalVisibility, setSelectedModalVisibility] = useState<
      ModalVisibilityObject | undefined
    >(undefined);

    const featureFlags = useAppSelector(UserSelectors.selectFeatureFlags);

    const selectedSoundAndAlertConfig = useAppSelector(
      SettingSelectors.selectSelectedSoundAndAlertConfig
    );

    const soundAndAlertConfig = featureFlags.kds_sounds_and_alerts_config;

    return (
      <>
        <Setting title={t("settings.alerts.title")} iconName="volume-source">
          <View
            style={{ flexDirection: "row", gap: 12, paddingHorizontal: 36 }}
          >
            <View
              style={{
                height: 45,
                width: 45,
              }}
            />
            <View style={{ flex: 3 }} />
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 6,
                flex: 2,
              }}
            >
              <MaterialCommunityIcons
                size={24}
                name="volume-high"
                color="#6C94DF"
              />
              <Text
                font="medium"
                color="#6C94DF"
                size={16}
                style={{ flexWrap: "wrap", flexShrink: 1 }}
              >
                {t("settings.alerts.sound")}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 6,
                flex: 2,
              }}
            >
              <MaterialCommunityIcons size={24} name="repeat" color="#6C94DF" />
              <Text
                font="medium"
                color="#6C94DF"
                size={16}
                style={{ flexWrap: "wrap", flexShrink: 1 }}
              >
                {t("settings.alerts.soundRepetition")}
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 6,
                flex: 2,
              }}
            >
              <MaterialCommunityIcons
                size={24}
                name="message-alert"
                color="#6C94DF"
              />
              <Text
                font="medium"
                color="#6C94DF"
                size={16}
                style={{ flexWrap: "wrap", flexShrink: 1 }}
              >
                {t("settings.alerts.banner")}
              </Text>
            </View>
          </View>
          <SectionContainer titleKey="settings.alerts.newOrder.title">
            <AlertRow
              title={t("settings.alerts.newOrder.delivery")}
              option={soundAndAlertConfig?.new_order.delivery}
              selectedOption={selectedSoundAndAlertConfig?.new_order.delivery}
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "new_order",
                  variant: "delivery",
                  options: type,
                });
              }}
              icon={
                <MaterialCommunityIcons
                  name={getIconNameForOrderKind(OrderKinds.DELIVERY)}
                  size={32}
                />
              }
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.newOrder.pickUp")}
              option={soundAndAlertConfig?.new_order.collection}
              selectedOption={selectedSoundAndAlertConfig?.new_order.collection}
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "new_order",
                  variant: "collection",
                  options: type,
                });
              }}
              icon={
                <MaterialCommunityIcons
                  name={getIconNameForOrderKind(OrderKinds.CUSTOMER_PICKUP)}
                  size={32}
                />
              }
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.newOrder.walkIn")}
              option={soundAndAlertConfig?.new_order.walk_in}
              selectedOption={selectedSoundAndAlertConfig?.new_order.walk_in}
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "new_order",
                  variant: "walk_in",
                  options: type,
                });
              }}
              icon={
                <MaterialCommunityIcons
                  name={getIconNameForOrderKind(OrderKinds.WALK_IN)}
                  size={32}
                />
              }
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.newOrder.thirdPartyDelivery")}
              option={soundAndAlertConfig?.new_order.third_party_delivery}
              selectedOption={
                selectedSoundAndAlertConfig?.new_order.third_party_delivery
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "new_order",
                  variant: "third_party_delivery",
                  options: type,
                });
              }}
              icon={
                <MaterialCommunityIcons
                  name={getIconNameForOrderKind(
                    OrderKinds.THIRD_PARTY_DELIVERY
                  )}
                  size={32}
                />
              }
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.newOrder.driveThrough")}
              option={soundAndAlertConfig?.new_order.drive_thru}
              selectedOption={selectedSoundAndAlertConfig?.new_order.drive_thru}
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "new_order",
                  variant: "drive_thru",
                  options: type,
                });
              }}
              icon={
                <MaterialCommunityIcons
                  name={getIconNameForOrderKind(OrderKinds.DRIVE_THRU)}
                  size={32}
                />
              }
              onPlaySound={playSound}
            />
          </SectionContainer>
          <SectionContainer titleKey="settings.alerts.orderNotifications.title">
            <AlertRow
              title={t(
                "settings.alerts.orderNotifications.itemChangedItemAddedOrderChanged"
              )}
              option={
                soundAndAlertConfig?.order_notifications
                  .order_changed_item_changed_item_added
              }
              selectedOption={
                selectedSoundAndAlertConfig?.order_notifications
                  .order_changed_item_changed_item_added
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "order_notifications",
                  variant: "order_changed_item_changed_item_added",
                  options: type,
                });
              }}
              icon={<MaterialCommunityIcons name={"sync-alert"} size={32} />}
              onPlaySound={playSound}
            />
            <AlertRow
              title={t(
                "settings.alerts.orderNotifications.orderCancelledItemCancelled"
              )}
              option={
                soundAndAlertConfig?.order_notifications
                  .order_cancelled_item_removed
              }
              selectedOption={
                selectedSoundAndAlertConfig?.order_notifications
                  .order_cancelled_item_removed
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "order_notifications",
                  variant: "order_cancelled_item_removed",
                  options: type,
                });
              }}
              onPlaySound={playSound}
              icon={<MaterialCommunityIcons name={"close-thick"} size={32} />}
            />
          </SectionContainer>
          <SectionContainer titleKey="settings.alerts.companyNotifications.title">
            <AlertRow
              title={t("settings.alerts.companyNotifications.criticalpriority")}
              option={soundAndAlertConfig?.company_notifications.critical}
              selectedOption={
                selectedSoundAndAlertConfig?.company_notifications.critical
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "company_notifications",
                  variant: "critical",
                  options: type,
                });
              }}
              icon={<PriorityIcon priority={NotificationPriority.CRITICAL} />}
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.companyNotifications.highpriority")}
              option={soundAndAlertConfig?.company_notifications.high}
              selectedOption={
                selectedSoundAndAlertConfig?.company_notifications.high
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "company_notifications",
                  variant: "high",
                  options: type,
                });
              }}
              icon={<PriorityIcon priority={NotificationPriority.HIGH} />}
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.companyNotifications.mediumpriority")}
              option={soundAndAlertConfig?.company_notifications.medium}
              selectedOption={
                selectedSoundAndAlertConfig?.company_notifications.medium
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "company_notifications",
                  variant: "medium",
                  options: type,
                });
              }}
              icon={<PriorityIcon priority={NotificationPriority.NORMAL} />}
              onPlaySound={playSound}
            />
            <AlertRow
              title={t("settings.alerts.companyNotifications.lowpriority")}
              option={soundAndAlertConfig?.company_notifications.low}
              selectedOption={
                selectedSoundAndAlertConfig?.company_notifications.low
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "company_notifications",
                  variant: "low",
                  options: type,
                });
              }}
              icon={<PriorityIcon priority={NotificationPriority.LOW} />}
              onPlaySound={playSound}
            />
          </SectionContainer>
          <SectionContainer titleKey="settings.alerts.system.title">
            <AlertRow
              title={t("settings.alerts.system.noConnection")}
              option={soundAndAlertConfig?.system.no_internet_connection}
              selectedOption={
                selectedSoundAndAlertConfig?.system.no_internet_connection
              }
              onPress={(type) => {
                setSelectedModalVisibility({
                  category: "system",
                  variant: "no_internet_connection",
                  options: type,
                });
              }}
              onPlaySound={playSound}
              disableAlertDisplayTimeMsOption
              icon={<MaterialCommunityIcons name={"web-off"} size={32} />}
            />
          </SectionContainer>
        </Setting>
        {!!selectedModalVisibility && (
          <AlertOptionModal
            isOpen={!!selectedModalVisibility}
            onClose={() => {
              setSelectedModalVisibility(undefined);
            }}
            soundAndAlertConfig={soundAndAlertConfig}
            selectedModalVisibility={selectedModalVisibility}
            selectedSoundAndAlertConfig={selectedSoundAndAlertConfig}
            onPlaySound={playSound}
          />
        )}
      </>
    );
  },
  {
    screen: RouteNames.Home.Settings.Alerts,
    key: "settings-alerts",
    fallback: <SettingError />,
  }
);
