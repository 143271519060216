import { MaterialCommunityIcons } from "@expo/vector-icons";
import { DrawerContentScrollView } from "@react-navigation/drawer";
import {
  getFocusedRouteNameFromRoute,
  useLinkProps,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { convertHostNameToBaseUrl, DEFAULT_BASE_URL } from "API";
import { HorizontalDivider, Text, WebImage } from "components";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import Constants from "expo-constants";
import * as Device from "expo-device";
import * as Localization from "expo-localization";
import * as Updates from "expo-updates";
import { NavigationButtonTertiary } from "navigation/components/SideBar";
import { RouteNames } from "navigation/linkingConfig";
import { useTranslation } from "react-i18next";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { AppSelectors } from "store/App";
import { AuthActions } from "store/Auth";
import { SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";
import { MaterialCommunityIconName } from "types/icons";
import { APP_VARIANT } from "util/helpers";

const AppInfo = () => {
  const { t } = useTranslation();

  const environment = useSelector(AppSelectors.selectEnvironment);

  return (
    <View style={styles.appInfo}>
      {environment &&
        convertHostNameToBaseUrl(environment.hostname) !== DEFAULT_BASE_URL && (
          <Text style={styles.versionText}>
            {t("settings.drawer.systemInfo.environment", {
              value: environment.name,
            })}
          </Text>
        )}
      <Text style={styles.versionText}>
        {t("settings.drawer.systemInfo.build", {
          value1: Constants.expoConfig.version || "N/A",
          value2: APP_VARIANT,
        })}
      </Text>
      {/*<Text style={styles.versionText}>
        {t('settings.drawer.systemInfo.release', {
          value1: Constants.expoConfig.revisionId || 'N/A',
          value2: Updates.releaseChannel || 'N/A',
        })}
      </Text>*/}
      <Text style={styles.versionText}>
        {t("settings.drawer.systemInfo.update", {
          value: Updates.updateId || "N/A",
        })}
      </Text>
      <Text style={[styles.versionText, { marginTop: 10 }]}>
        {Device.modelName}, {Device.osVersion}
      </Text>
      <Text style={styles.versionText}>
        {Localization.locale}, {Localization.timezone}
      </Text>
    </View>
  );
};

const Navigation = () => {
  const navigation = useNavigation<any>();
  const logo = useSelector(UserSelectors.selectLogo);

  return (
    <View style={styles.navigation}>
      <TouchableOpacity
        activeOpacity={DEFAULT_ACTIVE_OPACITY}
        hitSlop={8}
        onPress={() => {
          navigation.canGoBack()
            ? navigation.goBack()
            : navigation.navigate({ name: RouteNames.Home.Main._ });
        }}
      >
        <MaterialCommunityIcons
          name="chevron-left"
          size={28}
          color={colors.darkGray}
          style={{ padding: 4 }}
        />
      </TouchableOpacity>
      {!!logo && (
        <View style={{ marginTop: 4 }}>
          <WebImage
            uri={logo}
            width={44}
            height={44}
            shape={"rect"}
            borderRadius={5}
          />
        </View>
      )}
    </View>
  );
};

const HubInfo = () => {
  const hubName = useSelector(UserSelectors.selectHubName);
  const address = useSelector(UserSelectors.selectAddress);

  return (
    <View style={styles.hubInfo}>
      <Text style={styles.hubNameText} font={"bold"} size={20}>
        {hubName}
      </Text>
      <Text style={styles.hubAddressText} font={"light"} size={14}>
        {address.line_1}
      </Text>
    </View>
  );
};

type NavItemProps = {
  routeName: string;
  iconName: MaterialCommunityIconName;
  isSelected: boolean;
} & RequireAtLeastOne<{
  labelKey: string;
  label: string;
}>;

const NavItem = (props: NavItemProps) => {
  const { routeName, ...rest } = props;
  const navigationProps = useLinkProps({ to: { screen: routeName } });

  return (
    <NavigationButtonTertiary
      {...rest}
      onPress={() => {
        navigationProps.onPress();
      }}
    />
  );
};

const Drawer = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const categories = useSelector(SettingSelectors.selectAllCategories);
  const orderKinds = useSelector(SettingSelectors.selectAllOrderKinds);
  const selectedCategoriesCount = useSelector(
    SettingSelectors.selectSelectedCategoriesCount
  );

  const selectedOrderKindsCount = useSelector(
    SettingSelectors.selectSelectedOrderKindsCount
  );

  const signOut = () => {
    dispatch(AuthActions.signOut());
  };

  const route = useRoute();
  const focusedRouteName = getFocusedRouteNameFromRoute(route);
  const activeRouteName = focusedRouteName || RouteNames.Home.Settings.General;

  return (
    <DrawerContentScrollView
      {...props}
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
    >
      <Navigation />
      <HubInfo />
      <Text
        style={styles.sectionText}
        font={"bold"}
        id={"settings.drawer.preferences"}
        size={14}
      />
      <NavItem
        labelKey="settings.drawer.general"
        iconName="cog-outline"
        routeName={RouteNames.Home.Settings.General}
        isSelected={activeRouteName === RouteNames.Home.Settings.General}
      />
      <NavItem
        labelKey="settings.drawer.alerts"
        iconName="volume-source"
        routeName={RouteNames.Home.Settings.Alerts}
        isSelected={activeRouteName === RouteNames.Home.Settings.Alerts}
      />
      <NavItem
        labelKey="settings.drawer.interface"
        iconName="monitor-edit"
        routeName={RouteNames.Home.Settings.Interface}
        isSelected={activeRouteName === RouteNames.Home.Settings.Interface}
      />
      <NavItem
        labelKey="settings.drawer.timing"
        iconName="clock-outline"
        routeName={RouteNames.Home.Settings.Timing}
        isSelected={activeRouteName === RouteNames.Home.Settings.Timing}
      />
      {/*<NavItem
        labelKey="settings.drawer.sounds"
        iconName="volume-source"
        routeName={RouteNames.Home.Settings.Sounds}
        isSelected={activeRouteName === RouteNames.Home.Settings.Sounds}
      />*/}
      <NavItem
        label={t("settings.drawer.orderKinds", {
          count: selectedOrderKindsCount,
          total: orderKinds.length,
        })}
        iconName="silverware-variant"
        routeName={RouteNames.Home.Settings.OrderKinds}
        isSelected={activeRouteName === RouteNames.Home.Settings.OrderKinds}
      />
      <NavItem
        label={t("settings.drawer.categories", {
          count: selectedCategoriesCount,
          total: categories.length,
        })}
        iconName="food"
        routeName={RouteNames.Home.Settings.Categories}
        isSelected={activeRouteName === RouteNames.Home.Settings.Categories}
      />
      <HorizontalDivider style={styles.divider} />
      <AppInfo />
      <HorizontalDivider style={styles.divider} />
      <NavigationButtonTertiary
        labelKey="settings.drawer.logout"
        iconName="logout-variant"
        onPress={signOut}
        isSelected={false}
      />
    </DrawerContentScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 8,
    marginVertical: 12,
  },
  contentContainer: {
    flexGrow: 1,
  },
  appInfo: {
    flexGrow: 1,
    justifyContent: "center",
    marginVertical: 10,
    paddingVertical: 2,
  },
  divider: {
    marginVertical: 16,
  },
  navigation: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  hubInfo: {
    marginTop: 12,
  },
  hubNameText: {
    color: colors.darkGray,
  },
  hubAddressText: {
    color: colors.darkGray,
    marginTop: 4,
  },
  versionText: {
    fontSize: 12,
    color: colors.gray,
    textAlign: "center",
  },
  sectionText: {
    marginTop: 20,
    marginBottom: 8,
    color: colors.darkGray,
  },
});

export default Drawer;
