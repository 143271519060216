import React, { ComponentType } from "react";
import { createCtx } from "util/createCtx";
import { Alert } from "./types";

type AlertControlsContext = {
  add(alert: Alert): string;
  dismiss(uuid: string): void;
  dismissAll(): void;
};

const [useAlertControls, ControlsProvider] = createCtx<AlertControlsContext>();

type WithAlertControlsProps = Pick<AlertControlsContext, "dismissAll"> & {
  addAlert: AlertControlsContext["add"];
  dismissAlert: AlertControlsContext["dismiss"];
};

function withAlertControls<T extends object>(
  WrappedComponent: ComponentType<T>
) {
  const ComponentWithControls = (
    props: Omit<T, keyof WithAlertControlsProps>
  ) => {
    const { add, dismiss } = useAlertControls();

    return (
      <WrappedComponent
        {...(props as T)}
        addAlert={add}
        dismissAlert={dismiss}
      />
    );
  };

  return ComponentWithControls;
}

export {
  AlertControlsContext,
  useAlertControls,
  withAlertControls,
  ControlsProvider,
  WithAlertControlsProps,
};
