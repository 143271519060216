/* eslint-disable @typescript-eslint/no-require-imports */

export default {
  bell: require("./bell.mp3"),
  light: require("./light.mp3"),
  point: require("./point.mp3"),
  bada_bum_tss_1: require("./bada_bum_tss_1.mp3"),
  cartonish_voice_alert_1: require("./cartonish_voice_alert_1.mp3"),
  country_melody_1: require("./country_melody_1.mp3"),
  country_melody_2: require("./country_melody_2.mp3"),
  epic_braam_1: require("./epic_braam_1.mp3"),
  frog_croaking_1: require("./frog_croaking_1.mp3"),
  male_voice_alert_1: require("./male_voice_alert_1.mp3"),
  male_voice_alert_2: require("./male_voice_alert_2.mp3"),
  rooster_croowing_1: require("./rooster_croowing_1.mp3"),
  shotgun_1: require("./shotgun_1.mp3"),
  trumpet_melody_1: require("./trumpet_melody_1.mp3"),
  tune_1: require("./tune_1.mp3"),
  tune_2: require("./tune_2.mp3"),
  tune_3: require("./tune_3.mp3"),
};
