import { Audio, type AVPlaybackSource } from "expo-av";
import { randomUUID } from "expo-crypto";
import { useRef } from "react";

const SLEEP_DURATION_MS = 3000;

const sleep = async (ms: number) => await new Promise((r) => setTimeout(r, ms));

const handleSoundError = (error: unknown) => {
  console.error("Sound error:", error);
};

const isSoundLoaded = async (sound: Audio.Sound) => {
  const status = await sound.getStatusAsync();
  return status.isLoaded;
};

const replaySound = async (sound: Audio.Sound) => {
  await sound.replayAsync().catch(handleSoundError);
};

const unloadSound = async (sound: Audio.Sound) => {
  await sound.unloadAsync().catch(handleSoundError);
};

export const useSound = () => {
  const soundQueue = useRef<
    { audio: Audio.Sound; uuid: string; loopCount: number }[]
  >([]);

  const getLastAudio = () =>
    soundQueue.current.length >= 1
      ? soundQueue.current[soundQueue.current.length - 1]
      : undefined;

  async function playSound(
    source: AVPlaybackSource,
    uuid?: string,
    loopCount = 1
  ) {
    const uuidWithFallback = uuid ?? randomUUID();
    if (loopCount <= 0) return;

    const { sound } = await Audio.Sound.createAsync(
      source,
      undefined,
      async (playbackStatus) => {
        if (!playbackStatus.isLoaded) return;

        if (playbackStatus.didJustFinish) {
          await sleep(SLEEP_DURATION_MS);

          const currentAudioIndex = soundQueue.current.findIndex(
            (data) => data.uuid === uuidWithFallback
          );

          if (currentAudioIndex === -1) {
            const lastAudio = getLastAudio();
            if (lastAudio) {
              await replaySound(lastAudio.audio);
            }
            return;
          }

          const currentAudio = soundQueue.current[currentAudioIndex];
          currentAudio.loopCount -= 1;
          console.log(
            soundQueue.current.map((s) => `${s.uuid}-${s.loopCount}`)
          );

          if (currentAudioIndex === soundQueue.current.length - 1) {
            if (currentAudio.loopCount > 0) {
              await replaySound(sound);
            } else {
              await unloadSound(sound);
              soundQueue.current = soundQueue.current.filter(
                (data) => data.uuid !== currentAudio.uuid
              );

              const lastAudio = getLastAudio();
              if (lastAudio) {
                await replaySound(lastAudio.audio);
              }
            }
          }
        }
      }
    );

    const data = { audio: sound, uuid: uuidWithFallback, loopCount };

    const lastAudio = getLastAudio();
    if (lastAudio) {
      await lastAudio.audio.stopAsync().catch(handleSoundError);
    }

    soundQueue.current.push(data);

    await replaySound(getLastAudio().audio);

    return { uuid: uuidWithFallback };
  }

  async function dismissSound(uuid: string) {
    const temp = soundQueue.current;
    soundQueue.current = soundQueue.current.filter(
      (data) => data.uuid !== uuid
    );

    const searchedAudio = temp.find((data) => data.uuid === uuid)?.audio;
    if (searchedAudio) {
      await searchedAudio.stopAsync().catch(handleSoundError);
      await unloadSound(searchedAudio);

      if (temp[temp.length - 1].uuid === uuid) {
        const lastAudio = getLastAudio();
        if (lastAudio) {
          await replaySound(lastAudio.audio);
        }
      }
    }
  }

  async function dismissAllSound() {
    const temp = soundQueue.current;
    soundQueue.current = [];

    for (const sound of temp) {
      await sound.audio.stopAsync().catch(handleSoundError);
      await unloadSound(sound.audio);
    }
  }

  return {
    playSound,
    dismissSound,
    dismissAllSound,
  };
};
