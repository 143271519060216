import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { NoConnectionBanner } from "components/NoConnectionBanner";
import { PusherManager } from "components/PusherManager";
import { useSoundAlertManager } from "components/useSoundAlertManager";
import Settings from "modules/Settings";
import { useSyncLocale } from "util/localization/util";
import StoreManager from "../StoreManager";
import { EnvironmentWatcher } from "./components/EnvironmentWatcher";
import { RouteNames } from "./linkingConfig";
import MainNavigator from "./MainNavigator";

const MainStack = createNativeStackNavigator();

const AuthenticatedNavigator = () => {
  useSyncLocale();
  useSoundAlertManager();

  return (
    <>
      <StoreManager />
      <PusherManager />
      <NoConnectionBanner />
      <EnvironmentWatcher>
        <MainStack.Navigator
          screenOptions={{
            headerShown: false,
          }}
          initialRouteName={RouteNames.Home.Main._}
        >
          <MainStack.Screen
            name={RouteNames.Home.Main._}
            component={MainNavigator}
          />
          <MainStack.Screen
            name={RouteNames.Home.Settings._}
            component={Settings}
            options={{ animation: "fade" }}
          />
        </MainStack.Navigator>
      </EnvironmentWatcher>
    </>
  );
};

export default AuthenticatedNavigator;
