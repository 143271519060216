import Color from "color";
import { Button, Text } from "components";
import colors from "config/colors";
import { useGetTimeLabel } from "modules/Notifications/helpers";
import { PriorityIcon } from "navigation/components/SideBar/NotificationButton/PriorityIcon";
import {
  TouchableHighlight,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { NotificationPriority } from "store/Notifications/enums";

type BaseAlertProps = {
  createdAt: string;
  priority?: NotificationPriority;
  title: string;
  message?: string;
  onPress?: () => void;
  onDismiss?: () => void;
};

export const BaseAlert = (props: BaseAlertProps) => {
  const {
    createdAt,
    priority = NotificationPriority.NORMAL,
    title,
    message,
    onPress,
    onDismiss,
  } = props;

  const getTimeLabel = useGetTimeLabel();
  return (
    <TouchableHighlight
      style={{
        backgroundColor: Color("#3bc3ed").fade(0.8).hexa(),
        paddingHorizontal: 12,
      }}
      underlayColor={Color("#3bc3ed").fade(0.5).hexa()}
      onPress={onPress}
    >
      <View
        style={{
          paddingVertical: 10,
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <View style={{ marginRight: 10, minWidth: 70, gap: 4 }}>
          <View style={{ flexDirection: "row", gap: 4, alignItems: "center" }}>
            <PriorityIcon priority={priority} size={32} />
            <Text size={16} font={"bold"} color={colors.text.dark}>
              {title}
            </Text>
          </View>
          <Text color={"#9b9b9b"} size={12}>
            {getTimeLabel(createdAt)}
          </Text>
        </View>
        <View
          style={{
            marginRight: "auto",
            flexWrap: "wrap",
            flexGrow: 1,
            flexShrink: 1,
            gap: 4,
          }}
        >
          {!!message && (
            <Text
              color={colors.text.dark}
              numberOfLines={3}
              style={{
                maxWidth: "100%",
              }}
              font="medium"
              size={16}
            >
              {message}
            </Text>
          )}
        </View>

        <View>
          <Button
            disallowInterruption
            titleId={"notifications.dismiss"}
            style={{
              backgroundColor: Color(colors.gray).fade(0.3).hexa(),
              marginLeft: 50,
              borderRadius: 50,
              width: 140,
            }}
            innerStyle={{
              paddingHorizontal: 0,
              paddingVertical: 10,
              borderRadius: 50,
            }}
            font={"medium"}
            onPress={onDismiss}
            size={14}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
};
