import { lockAsync, OrientationLock } from "expo-screen-orientation";
import { useCallback, useEffect, useState } from "react";
import { StatusBar } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import "util/localization";
import { ErrorBoundary, ToastErrorHandler } from "components";
import { ToastContainer } from "components/Snackbar";
import { AlertsContainer, AlertsProvider } from "modules/Alerts/AlertsProvider";
import Router from "navigation/RootRouter";
import { persistor, store } from "store";
import { isWeb } from "util/helpers";
import "assets/vendorCSS";
import { NavigationContainer } from "@react-navigation/native";
import { useFonts } from "assets/fonts";
import * as SplashScreen from "expo-splash-screen";
import { capitalize } from "lodash";
import { NotificationStateContextProvider } from "navigation/components/SideBar/NotificationButton/NotificationStateContext";
import { ScreenContextProvider } from "navigation/context/ScreenContextProvider";
import linkingConfig from "navigation/linkingConfig";

export default function App() {
  const [isFontLoaded] = useFonts();

  const init = useCallback(async () => {
    if (!isWeb) {
      await lockAsync(OrientationLock.LANDSCAPE);
    }

    // store rehydration and startup sequence are delayed until the fonts are loaded
    persistor.persist();
  }, []);

  const [appIsReady, setAppIsReady] = useState(false);

  useEffect(() => {
    async function prepare() {
      try {
        await init();
      } catch (e) {
        console.warn(e);
      } finally {
        setAppIsReady(true);
      }
    }

    if (isFontLoaded) {
      prepare();
    }
  }, [init, isFontLoaded]);

  const onLayoutRootView = useCallback(() => {
    if (appIsReady) {
      SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <SafeAreaProvider onLayout={onLayoutRootView}>
      <StatusBar
        barStyle="light-content"
        translucent
        backgroundColor="#FFFFFF00"
      />
      <ErrorBoundary
        beforeCapture={([scope], errorUUID) => {
          scope.setTag("errorUUID", errorUUID);
        }}
      >
        <ToastContainer>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <NavigationContainer
                  linking={linkingConfig}
                  documentTitle={{
                    formatter: (options, route) =>
                      `${options?.title ?? capitalize(route?.name)} | Kitchen`,
                  }}
                >
                  <NotificationStateContextProvider>
                    <ScreenContextProvider>
                      <PaperProvider>
                        <ToastErrorHandler>
                          <AlertsProvider>
                            <AlertsContainer />
                            <Router />
                          </AlertsProvider>
                        </ToastErrorHandler>
                      </PaperProvider>
                    </ScreenContextProvider>
                  </NotificationStateContextProvider>
                </NavigationContainer>
              </PersistGate>
            </Provider>
          </GestureHandlerRootView>
        </ToastContainer>
      </ErrorBoundary>
    </SafeAreaProvider>
  );
}
