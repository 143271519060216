import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "components";
import colors from "config/colors";
import debounce from "lodash/debounce";
import { useMemo } from "react";
import { TouchableOpacity, View } from "react-native";

export type AlertOptionProps<T extends string> = {
  title: T | undefined;
  isLocked?: boolean;
  onPress?: () => void;
  onPlaySound?: (name: T) => Promise<void>;
  hasPlayIcon?: boolean;
  hiddenPlayIcon?: boolean;
};

export const AlertOption = <T extends string>(props: AlertOptionProps<T>) => {
  const { title, isLocked, hiddenPlayIcon, onPress, onPlaySound, hasPlayIcon } =
    props;

  const debouncedPlaySound = useMemo(
    () =>
      debounce((_title: T) => {
        onPlaySound?.(_title);
      }, 300),
    [onPlaySound]
  );

  return (
    <View style={{ flexDirection: "row", alignItems: "center", gap: 6 }}>
      {hasPlayIcon && (
        <TouchableOpacity
          onPress={() => {
            debouncedPlaySound(title);
          }}
          style={{
            opacity: hiddenPlayIcon ? 0 : 1,
            pointerEvents: hiddenPlayIcon ? "none" : "auto",
          }}
        >
          <MaterialCommunityIcons
            name="play-circle"
            color={colors.main}
            size={20}
          />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        disabled={isLocked}
        onPress={onPress}
        style={{ flex: 1 }}
      >
        <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
          <Text size={14} color="#979797" style={{ flexShrink: 1 }}>
            {title.replaceAll("_", "_\u200B")}
          </Text>
          <MaterialCommunityIcons
            name={isLocked ? "lock" : "chevron-right"}
            size={isLocked ? 16 : 22}
            color="#979797"
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
