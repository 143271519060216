import useMount from "react-use/lib/useMount";
import { useAppDispatch, useAppSelector } from "store";
import { SettingActions, SettingSelectors } from "store/Settings";
import { UserSelectors } from "store/User";

type UnionObjectEntries<T> = T extends object
  ? { [K in keyof T]: [K, T[K]] }[keyof T]
  : never;

type Entries<T> = UnionObjectEntries<T>[];

export const useSoundAlertManager = () => {
  const selectedSoundAndAlertConfig = useAppSelector(
    SettingSelectors.selectSelectedSoundAndAlertConfig
  );

  const featureFlags = useAppSelector(UserSelectors.selectFeatureFlags);

  const soundAndAlertConfig = featureFlags?.kds_sounds_and_alerts_config;

  const dispatch = useAppDispatch();

  useMount(() => {
    const temp = Object.fromEntries(
      (
        Object.entries(soundAndAlertConfig) as Entries<
          typeof soundAndAlertConfig
        >
      ).map(([key1, value1]) => {
        return [
          key1,
          Object.fromEntries(
            (Object.entries(value1) as Entries<typeof value1>).map(
              ([key2, value2]) => {
                return [
                  key2,
                  Object.fromEntries(
                    (Object.entries(value2) as Entries<typeof value2>).map(
                      ([key3, value3]) => {
                        const mapSelectedKey = {
                          alert_display_time_ms_options:
                            "alert_display_time_ms_option",
                          sound_options: "sound_option",
                          sound_playback_config_options:
                            "sound_playback_config_option",
                        } satisfies Record<typeof key3, string>;

                        const selectedKey = mapSelectedKey[key3];

                        const selectedOption =
                          selectedSoundAndAlertConfig?.[key1]?.[key2]?.[
                            selectedKey
                          ];

                        if (!Array.isArray(value3)) {
                          return [selectedKey, null];
                        }

                        const index = value3.findIndex((data) => {
                          return (
                            // NOTE: need to compare as JSON.stringify due to some is object
                            JSON.stringify(data) ===
                            JSON.stringify(selectedOption)
                          );
                        });

                        if (index < 0) {
                          return [selectedKey, value3?.[0] ?? null];
                        } else {
                          return [selectedKey, selectedOption ?? null];
                        }
                      }
                    )
                  ),
                ];
              }
            )
          ),
        ];
      })
    ) as typeof selectedSoundAndAlertConfig;

    dispatch(SettingActions.setSoundAndAlertConfig(temp));
  });

  return null;
};
