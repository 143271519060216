import assets from "assets";
import { Text } from "components";
import { type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import type { SelectedSoundAndAlertOption } from "store/Settings/types";
import type { SoundAndAlertOption } from "store/User/types";
import { AlertOption } from "./AlertOption";
import { getOptionTitle } from "./getOptionTitle";

export type AlertRowProps = {
  title: string;
  option?: SoundAndAlertOption;
  selectedOption: SelectedSoundAndAlertOption;
  onPress?: (type: keyof SelectedSoundAndAlertOption) => void;
  onPlaySound?: (name: keyof typeof assets.sounds) => Promise<void>;
  icon?: ReactElement;
  disableSoundOption?: boolean;
  disableSoundPlaybackConfigOption?: boolean;
  disableAlertDisplayTimeMsOption?: boolean;
};

export const AlertRow = (props: AlertRowProps) => {
  const {
    title,
    icon,
    option,
    selectedOption,
    onPress,
    onPlaySound,
    disableSoundOption,
    disableSoundPlaybackConfigOption,
    disableAlertDisplayTimeMsOption,
  } = props;

  const isSoundOptionLocked = option?.sound_options?.length <= 1;
  const isSoundPlaybackConfigOptionLocked =
    option?.sound_playback_config_options?.length <= 1;
  const isAlertDisplayTimeMsOptionLocked =
    option?.alert_display_time_ms_options?.length <= 1;

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 6,
        gap: 12,
      }}
    >
      <View
        style={{
          height: 45,
          width: 45,
          overflow: "hidden",
          borderRadius: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </View>
      <View style={{ flex: 3 }}>
        <Text color="#3C3C3B" size={16}>
          {title}
        </Text>
      </View>
      <View
        style={{
          flex: 2,
        }}
      >
        {!disableSoundOption && (
          <AlertOption
            title={getOptionTitle(selectedOption.sound_option, "sound_option")}
            onPress={() => {
              onPress("sound_option");
            }}
            isLocked={isSoundOptionLocked}
            onPlaySound={onPlaySound}
            hasPlayIcon
            hiddenPlayIcon={!selectedOption.sound_option}
          />
        )}
      </View>
      <View
        style={{
          flex: 2,
        }}
      >
        {!disableSoundPlaybackConfigOption && (
          <AlertOption
            title={getOptionTitle(
              selectedOption.sound_playback_config_option,
              "sound_playback_config_option"
            )}
            onPress={() => {
              onPress("sound_playback_config_option");
            }}
            isLocked={isSoundPlaybackConfigOptionLocked}
          />
        )}
      </View>
      <View
        style={{
          flex: 2,
        }}
      >
        {!disableAlertDisplayTimeMsOption && (
          <AlertOption
            title={getOptionTitle(
              selectedOption.alert_display_time_ms_option,
              "alert_display_time_ms_option"
            )}
            onPress={() => {
              onPress("alert_display_time_ms_option");
            }}
            isLocked={isAlertDisplayTimeMsOptionLocked}
          />
        )}
      </View>
    </View>
  );
};
