import AsyncStorage from "@react-native-async-storage/async-storage";
import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch,
  useSelector,
  useStore,
  type TypedUseSelectorHook,
} from "react-redux";
import { combineReducers } from "redux";
import { createMigrate, persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { AppReducer } from "store/App";
import { BundleMigrationReducer } from "store/BundleMigration";
import { DeviceReducer } from "store/Device";
import { NotificationsReducer } from "store/Notifications";
import { OrdersReducer } from "store/Orders";
import { SettingsReducer } from "store/Settings";
import { UserReducer } from "store/User";
import { sentryReduxEnhancer } from "util/sentry";
import { runMiddlewares } from "./middlewares";
import StatusReducer from "./StatusReducer";
import storeRegistry from "./storeRegistry";
import { settingsMigrations } from "./util/migrations/settingMigration";
import { userMigrations } from "./util/migrations/userMigration";
import { onStart } from "./util/sagas";

const IS_DEBUGGING_MIGRATIONS = __DEV__;

const UserPersistConfig = {
  key: "user",
  storage: AsyncStorage,
  version: 2,
  timeout: 0,
  blacklist: ["_token", "_email", "_accounts", "selectedAccountReady"],
  migrate: createMigrate(userMigrations as any, {
    debug: IS_DEBUGGING_MIGRATIONS,
  }),
};

const SettingsPersistConfig = {
  key: "settings",
  storage: AsyncStorage,
  version: 8,
  timeout: 0,
  migrate: createMigrate(settingsMigrations as any, {
    debug: IS_DEBUGGING_MIGRATIONS,
  }),
};

const AppPersistConfig = {
  key: "app",
  storage: AsyncStorage,
  version: 1,
  timeout: 0,
  blacklist: ["updatedAt", "errors", "selectedOrderUUID"],
};

const DevicesPersistConfig = {
  key: "devices",
  storage: AsyncStorage,
  version: 1,
  timeout: 0,
  whitelist: ["registeredUUID", "seenScreenSelection"],
};

const rootReducer = combineReducers({
  user: persistReducer(UserPersistConfig, UserReducer),
  categories: persistReducer(SettingsPersistConfig, SettingsReducer),
  orders: OrdersReducer,
  notifications: NotificationsReducer,
  requestStatuses: StatusReducer,
  app: persistReducer(AppPersistConfig, AppReducer),
  devices: persistReducer(DevicesPersistConfig, DeviceReducer),
  bundleMigration: BundleMigrationReducer,
});

const sagaMiddleware = createSagaMiddleware();
const reduxToolkitMiddlewareOptions = {
  thunk: true,
  // remove serialization to ensure compatibility with redux-persist
  serializableCheck: false,
  immutableCheck: false,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware(
      reduxToolkitMiddlewareOptions
    ).concat(sagaMiddleware);

    return middleware;
  },
  devTools: process.env.NODE_ENV === "development",
  enhancers: (getDefaultEnhancers) => {
    const enhancers = getDefaultEnhancers();
    if (sentryReduxEnhancer) {
      enhancers.push(sentryReduxEnhancer);
    }
    return enhancers;
  },
});

export type Store = typeof store;
storeRegistry.register(store);

// @ts-expect-error bad typings
export const persistor = persistStore(store, { manualPersist: true }, () => {
  sagaMiddleware.run(onStart);
});

runMiddlewares(store, sagaMiddleware);

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;
