import Color from "color";
import { Button } from "components";
import colors from "config/colors";
import * as Crypto from "expo-crypto";
import { Alert } from "modules/Alerts/Alert";
import { ControlsProvider, useAlertControls } from "modules/Alerts/context";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FlatList, Platform, StyleSheet, View } from "react-native";
import { useSelector } from "react-redux";
import usePrevious from "react-use/lib/usePrevious";
import { UserSelectors } from "store/User";
import { createCtx } from "util/createCtx";
import { useSound } from "util/hooks/useSound";
import type { AlertPayloadBase } from "./types";
import { type Alert as AlertType } from "./types";

const [useAlerts, Provider] = createCtx<AlertType[]>();

const DEFAULT_OPTIONS: AlertType["options"] = {
  auto_dismiss_timeout_ms: null,
  dismissible: true,
  sound: undefined,
  hiddenAlert: true,
};

function AlertsProvider(props: { children: ReactNode }) {
  const { children } = props;
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  const { playSound, dismissSound, dismissAllSound } = useSound();

  const isAuthenticated = useSelector(UserSelectors.selectIsAuthenticated);
  const isAuthenticatedPrev = usePrevious(isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated && isAuthenticatedPrev) {
      setAlerts([]);
    }
  }, [isAuthenticated, isAuthenticatedPrev]);

  const contextValue = useMemo(() => {
    return {
      add: (alert: AlertPayloadBase) => {
        const uuid = Crypto.randomUUID();

        if (alert.options.sound) {
          playSound(
            alert.options.sound,
            uuid,
            alert.options.soundLoopCount ?? 0
          );
        }

        if (!alert.options.hiddenAlert) {
          const dispatchedAt = Date.now();

          setAlerts((prevAlerts) => {
            return prevAlerts.concat({
              ...alert,
              options: {
                ...DEFAULT_OPTIONS,
                ...alert.options,
              },
              uuid,
              dispatchedAt,
            });
          });

          if (alert.options.auto_dismiss_timeout_ms) {
            setTimeout(() => {
              contextValue.dismiss(uuid);
            }, alert.options.auto_dismiss_timeout_ms);
          }
          return uuid;
        }
      },
      dismiss: (uuid: string) => {
        dismissSound(uuid);
        setAlerts((prevAlerts) =>
          prevAlerts.filter((item) => item.uuid !== uuid)
        );
      },
      dismissAll: () => {
        dismissAllSound();
        setAlerts((currentAlert) =>
          currentAlert.filter((alert) => !alert.options.dismissible)
        );
      },
    };
  }, [dismissAllSound, dismissSound, playSound]);

  return (
    <ControlsProvider value={contextValue}>
      <Provider value={alerts}>{children}</Provider>
    </ControlsProvider>
  );
}

function AlertsContainer() {
  const alerts = useAlerts();
  const { dismiss, dismissAll } = useAlertControls();

  const showDismissAllButton = useMemo(() => {
    const dismissableAlerts = alerts.filter(
      (alert) => alert.options.dismissible
    );

    return dismissableAlerts.length > 1;
  }, [alerts]);

  return (
    <View
      pointerEvents={"box-none"}
      style={[
        styles.container,
        Platform.select({
          web: styles.containerWeb,
          default: styles.containerNative,
        }),
      ]}
    >
      <FlatList
        ListHeaderComponent={
          showDismissAllButton && (
            <Button
              onPress={dismissAll}
              iconName={"close"}
              titleId={"notifications.dismissAll"}
              style={styles.dismissAll}
              innerStyle={styles.dismissAllInner}
              font={"medium"}
              size={14}
            />
          )
        }
        data={alerts}
        style={styles.scrollContainer}
        renderItem={({ item: alert }) => (
          <Alert alert={alert} onDismiss={() => dismiss(alert.uuid)} />
        )}
        contentContainerStyle={styles.innerContainer}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    bottom: 50,
    maxHeight: "100%",
    position: "absolute",
    top: 40,
    zIndex: 100,
  },
  containerNative: {
    alignSelf: "center",
  },
  containerWeb: {
    left: "50%",
    // @ts-expect-error this is fine for web-only style
    transform: [{ translateX: "-50%" }],
  },
  dismissAll: {
    alignSelf: "center",
    backgroundColor: Color(colors.veryLightBlue).desaturate(0.25).hexa(),
    borderRadius: 50,
    marginLeft: 50,
    marginVertical: 10,
    width: 200,
  },
  dismissAllInner: {
    borderRadius: 50,
    paddingVertical: 10,
  },
  innerContainer: {
    backgroundColor: "#14141466",
    borderRadius: 20,
  },
  scrollContainer: {
    flexGrow: 0,
    marginBottom: 5,
  },
});

export { AlertsProvider, AlertsContainer };
