import { useLinkProps } from "@react-navigation/native";
import Text from "components/Text";
import colors from "config/colors";
import { DEFAULT_ACTIVE_OPACITY } from "config/constants";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { AppSelectors } from "store/App";
import { UserSelectors } from "store/User";
import { RouteNames } from "../../linkingConfig";
import { LastUpdatedIndicator } from "./LastUpdatedIndicator";
import { commonStyles } from "./styles";

type PreparationTimeButtonProps = {
  onPress: () => void;
};

const PreparationTimeButton = ({ onPress }: PreparationTimeButtonProps) => {
  const { t } = useTranslation();

  const isSidebarMinimized = useSelector(AppSelectors.selectIsSidebarMinimized);
  const preparationTime = useSelector(UserSelectors.selectETA);

  return (
    <TouchableOpacity
      activeOpacity={DEFAULT_ACTIVE_OPACITY}
      style={{ marginTop: 16 }}
      onPress={onPress}
    >
      <Text color={colors.brightBeige} font="medium" align="center">
        {t(
          !isSidebarMinimized
            ? "sideBar.infoSection.avgOrderTime"
            : "sideBar.infoSection.avgOrderTime_short",
          {
            value: preparationTime ?? "?",
          }
        )}
      </Text>
    </TouchableOpacity>
  );
};

export function InfoSection() {
  const featureFlags = useSelector(UserSelectors.selectFeatureFlags);

  const promisedTimeSettingEnabled =
    featureFlags.display_estimated_preparation_time;

  const navigate = useLinkProps({
    to: {
      screen: RouteNames.Home.Settings._,
      params: {
        screen: RouteNames.Home.Settings.Timing,
      },
    },
  });

  return (
    <View style={commonStyles.container}>
      <LastUpdatedIndicator />
      {promisedTimeSettingEnabled && (
        <PreparationTimeButton
          onPress={() => {
            navigate.onPress?.();
          }}
        />
      )}
    </View>
  );
}
