import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Button, Modal, Spacer, Text } from "components";
import colors from "config/colors";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { useAppDispatch } from "store";
import { SettingActions, SettingSelectors } from "store/Settings";
import type { SelectedSoundAndAlertConfig } from "store/Settings/types";
import type { SoundsAndAlertsConfig } from "store/User/types";
import { getOptionTitle } from "./getOptionTitle";
import type { ModalVisibilityObject } from "./types";

export type AlertOptionModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  soundAndAlertConfig: SoundsAndAlertsConfig;
  selectedModalVisibility: ModalVisibilityObject;
  selectedSoundAndAlertConfig: SelectedSoundAndAlertConfig;
  onPlaySound: (title: string) => Promise<void>;
};
export const AlertOptionModal = (props: AlertOptionModalProps) => {
  const {
    isOpen,
    onClose,
    soundAndAlertConfig,
    selectedModalVisibility,
    selectedSoundAndAlertConfig,
    onPlaySound,
  } = props;

  const mapSelectedKey = {
    alert_display_time_ms_option: "alert_display_time_ms_options",
    sound_option: "sound_options",
    sound_playback_config_option: "sound_playback_config_options",
  } satisfies Record<typeof selectedModalVisibility.options, string>;

  const options =
    soundAndAlertConfig?.[selectedModalVisibility.category]?.[
      selectedModalVisibility.variant
    ]?.[mapSelectedKey[selectedModalVisibility.options]];

  const [selectedOption, setSelectedOption] = useState(
    selectedSoundAndAlertConfig?.[selectedModalVisibility.category]?.[
      selectedModalVisibility.variant
    ]?.[selectedModalVisibility.options]
  );

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleSave = () => {
    dispatch((action, getState) => {
      const state = getState();
      const selectSelectedSoundAndAlertConfig =
        SettingSelectors.selectSelectedSoundAndAlertConfig(state);

      try {
        const temp = JSON.parse(
          JSON.stringify(selectSelectedSoundAndAlertConfig)
        ) as typeof selectSelectedSoundAndAlertConfig;

        if (
          temp?.[selectedModalVisibility.category]?.[
            selectedModalVisibility.variant
          ]?.[selectedModalVisibility.options] !== undefined
        ) {
          temp[selectedModalVisibility.category][
            selectedModalVisibility.variant
          ][selectedModalVisibility.options] = selectedOption;
        }

        action(SettingActions.setSoundAndAlertConfig(temp));
      } catch (error) {
        console.error("Failed to save sound and alert config:", error);
      }
    });

    onClose?.();
  };

  return (
    <Modal
      visible={isOpen}
      onRequestClose={onClose}
      contentContainerStyle={{
        padding: 16,
        gap: 4,
      }}
    >
      <ScrollView>
        {options?.map((data: (typeof options)[number], index: number) => {
          const isSelected =
            JSON.stringify(selectedOption) === JSON.stringify(data);

          const selectedOptions = selectedModalVisibility.options;

          const value = getOptionTitle(data, selectedOptions);

          return (
            <TouchableOpacity
              key={index}
              style={{
                padding: 12,
                flexDirection: "row",
                height: 52,
                backgroundColor: isSelected ? colors.veryLightGray : undefined,
                borderRadius: 4,
                alignItems: "center",
              }}
              onPress={() => {
                if (typeof data === "string") {
                  onPlaySound(data);
                }
                setSelectedOption(data);
              }}
            >
              <Text size={16}>{value}</Text>

              {isSelected && (
                <>
                  <Spacer />
                  <MaterialCommunityIcons
                    name="check-bold"
                    color={colors.main}
                    size={28}
                  />
                </>
              )}
            </TouchableOpacity>
          );
        })}
      </ScrollView>

      <View style={{ flexDirection: "row", gap: 4, marginTop: 12 }}>
        <Button
          onPress={onClose}
          color={colors.lightGray}
          title={t("settings.alerts.cancel")}
          style={{ flex: 1 }}
          size={16}
        />
        <Button
          color={colors.main}
          onPress={handleSave}
          title={t("settings.alerts.save")}
          style={{ flex: 1 }}
          size={16}
        />
      </View>
    </Modal>
  );
};
