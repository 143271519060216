import { t } from "i18next";
import type { SelectedSoundAndAlertOption } from "store/Settings/types";

export const getOptionTitle = (
  options: SelectedSoundAndAlertOption[keyof SelectedSoundAndAlertOption],
  selectedOptions: keyof SelectedSoundAndAlertOption
) => {
  if (options === null) {
    return t("settings.alerts.disabled");
  }

  switch (selectedOptions) {
    case "sound_option":
      return options?.toString() ?? "";

    case "sound_playback_config_option":
      if (typeof options !== "object") {
        return options?.toString() ?? "";
      }

      if (options?.mode === "match_alert") {
        return t("settings.alerts.matchBanner");
      }

      switch (options?.times_to_repeat) {
        case 1:
          return t("settings.alerts.repeatOnce");
        case 2:
          return t("settings.alerts.repeatTwice");
        default:
          return t("settings.alerts.repeatNTimes", {
            repeat: options?.times_to_repeat,
          });
      }

    case "alert_display_time_ms_option": {
      if (typeof options !== "number") {
        return options?.toString() ?? "";
      }

      const INDEFINITE_DISPLAY_TIME = -1;
      const MS_TO_SEC = 1000;

      return options === INDEFINITE_DISPLAY_TIME
        ? t("settings.alerts.untilAcknowledged")
        : t("settings.alerts.sec", { value: options / MS_TO_SEC });
    }
    default:
      return options?.toString() ?? "";
  }
};
